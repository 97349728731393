import React, { useEffect, useState } from "react";
//Components
import {
  AppBar,
  Toolbar,
  ButtonGroup,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  Box,
  Button,
  Link
} from "@mui/material";
import { initGA, logPageView } from "../../helpers/analytics";

import { styled } from "@mui/material/styles";
//Icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const MagentaLink = styled("a")(({ theme }) => ({
  color: "black",
  textDecoration: "none",
  "&:hover": {
    color: "#d607d1"
  },
  "&:active": {
    color: "#d607d1"
  },
  marginLeft: "100px",
  fontSize: "18px",
  fontFamily: "Poppins",
  fontWeight: "regular"
}));
const SecondLineText = styled("span")(({ theme }) => ({
  display: "block"
}));

export default function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subDrawerOpen, setSubDrawerOpen] = useState(false);
  const pathname = window.location.pathname;
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSubDrawerOpen = () => {
    setSubDrawerOpen(true);
  };

  const handleSubDrawerClose = () => {
    setSubDrawerOpen(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "white",
          borderRadius: "0",
          boxShadow: "none",
          padding: "1rem 0"
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "center",
            boxShadow: "none",
            border: "none"
          }}
        >
          <img
            src="/logonuevo.svg"
            alt="Logo"
            onClick={() => (window.location.href = "/")}
            style={{ maxHeight: 70, marginTop: "0" }}
          />
          <ButtonGroup
            color="primary"
            aria-label="navigation"
            sx={{ display: { xs: "none", md: "none", lg: "flex" } }}
          >
            <MagentaLink
              href="/"
              sx={{ color: pathname === "/" ? "#d607d1" : "black" }}
            >
              Inicio
            </MagentaLink>
            <MagentaLink
              href="/la_corporacion"
              sx={{
                color: pathname === "/la_corporacion" ? "#d607d1" : "black"
              }}
            >
              La Corporación
            </MagentaLink>
            <MagentaLink
              href="/nosotras_nosotros"
              sx={{
                color: pathname === "/nosotras_nosotros" ? "#d607d1" : "black"
              }}
            >
              Nosotras y <SecondLineText>Nosotros</SecondLineText>
            </MagentaLink>
            <MagentaLink
              href="/proyectos"
              sx={{
                color: pathname === "/proyectos" ? "#d607d1" : "black"
              }}
            >
              Proyectos
            </MagentaLink>
            <MagentaLink
              href="/noticias"
              sx={{
                color: pathname === "/noticias" ? "#d607d1" : "black"
              }}
            >
              Noticias y <SecondLineText>Documentos</SecondLineText>
            </MagentaLink>
            <MagentaLink
              sx={{
                marginRight: "25px",
                color: pathname === "/contacto" ? "#d607d1" : "black"
              }}
              href="/contacto"
            >
              Contacto
            </MagentaLink>
          </ButtonGroup>
          {/* Mobile */}
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{
              display: { xs: "flex", md: "flex", lg: "none" },
              marginLeft: "auto",
              color: "#444749"
            }}
          >
            {drawerOpen ? (
              <CloseIcon />
            ) : (
              <MenuIcon sx={{ fontSize: "40px", marginTop: "-20px" }} />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Menu hamburguesa */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        ModalProps={{
          disableScrollLock: true
        }}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            overflowX: "hidden"
          }
        }}
      >
        {drawerOpen && (
          <IconButton
            color="inherit"
            aria-label="close"
            onClick={handleDrawerClose}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              zIndex: 1
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <List sx={{ marginTop: "38px", marginLeft: "89px" }}>
          <ListItem onClick={handleDrawerClose}>
            <Button href="/" color="inherit" sx={{ textTransform: "none" }}>
              <ListItemText primary="Inicio" />
            </Button>
          </ListItem>
          <ListItem onClick={handleDrawerClose}>
            <Button
              href="/la_corporacion"
              color="inherit"
              sx={{ textTransform: "none" }}
            >
              <ListItemText primary="La Corporación" />
            </Button>
          </ListItem>
          <ListItem onClick={handleDrawerClose}>
            <Button
              href="/nosotras_nosotros"
              color="inherit"
              sx={{ textTransform: "none" }}
            >
              <ListItemText primary="Nosotras y Nosotros" />
            </Button>
          </ListItem>
          <ListItem onClick={handleSubDrawerOpen}>
            <Button href="" color="inherit" sx={{ textTransform: "none" }}>
              <ListItemText primary="Proyectos" />
            </Button>
          </ListItem>
          <ListItem onClick={handleDrawerClose}>
            <Button
              href="/noticias"
              color="inherit"
              sx={{ textTransform: "none" }}
            >
              <ListItemText primary="Noticias y Documentos" />
            </Button>
          </ListItem>
          <ListItem onClick={handleDrawerClose}>
            <Button
              href="/contacto"
              color="inherit"
              sx={{ textTransform: "none" }}
            >
              <ListItemText primary="Contacto" />
            </Button>
          </ListItem>
        </List>
        <Container sx={{ marginTop: "114.5px", marginLeft: "99px" }}>
          <Typography sx={{ fontWeight: "bold" }}>Síguenos</Typography>
          <Box display="flex" sx={{ marginTop: "13px" }}>
            <Link
              href="https://www.facebook.com/InnovaRancagua"
              target="_blank"
            >
              <FacebookIcon sx={{ color: "black" }} />
            </Link>
            <Link
              href="https://www.instagram.com/desarrollo_innovacion/"
              target="_blank"
            >
              <InstagramIcon sx={{ marginLeft: "20px", color: "black" }} />
            </Link>
            <Link href="https://twitter.com/RancaguaInnova" target="_blank">
              <TwitterIcon sx={{ marginLeft: "20px", color: "black" }} />
            </Link>
          </Box>
        </Container>
        {/* SubDrawer para "Proyectos" */}
        <Drawer
          anchor="right"
          open={subDrawerOpen}
          onClose={handleSubDrawerClose}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "100%",
              backgroundColor: "white"
            }
          }}
        >
          {subDrawerOpen && (
            <IconButton
              color="inherit"
              aria-label="close"
              onClick={handleSubDrawerClose}
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                zIndex: 1
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <List sx={{ marginTop: "38px", marginLeft: "89px" }}>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "22px",
                paddingLeft: "20px",
                paddingTop: "30px"
              }}
            >
              Proyectos
            </Typography>
            <ListItem onClick={handleSubDrawerClose}>
              <Button
                href="/proyectos/innovacion-social"
                color="inherit"
                sx={{ textTransform: "none" }}
              >
                <ListItemText primary="Innovacion social" />
              </Button>
            </ListItem>
            <ListItem onClick={handleSubDrawerClose}>
              <Button
                href="/proyectos/desarrollo"
                color="inherit"
                sx={{ textTransform: "none" }}
              >
                <ListItemText primary="Desarrollo" />
              </Button>
            </ListItem>
            <ListItem onClick={handleSubDrawerClose}>
              <Button
                href="/proyectos/generando-identidad"
                color="inherit"
                sx={{ textTransform: "none" }}
              >
                <ListItemText primary="Generando Identidad" />
              </Button>
            </ListItem>
            <ListItem onClick={handleSubDrawerClose}>
              <Button
                href="/proyectos/seminarios-y-capacitaciones"
                color="inherit"
                sx={{ textTransform: "none" }}
              >
                <ListItemText primary="Seminarios y Capacitaciones" />
              </Button>
            </ListItem>
            <ListItem onClick={handleSubDrawerClose}>
              <Button
                href="/proyectos/seguridad"
                color="inherit"
                sx={{ textTransform: "none" }}
              >
                <ListItemText primary="Seguridad" />
              </Button>
            </ListItem>
            <Box
              sx={{
                display: { xs: "display", md: "display", lg: "none" },
                justifyContent: "left",
                marginTop: "104.5px",
                paddingRight: "250px"
              }}
            >
              <Button
                onClick={handleSubDrawerClose}
                variant="outlined"
                color="inherit"
                sx={{
                  border: "none",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  textTransform: "none"
                }}
              >
                <ArrowBackIcon sx={{ paddingRight: "5px" }} />
                atrás
              </Button>
            </Box>
          </List>
        </Drawer>
      </Drawer>
    </>
  );
}
